import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("partners", {
      partners: (state) => {
        return state.partners;
      },
    }),
  },
  methods: {
    // pushRouteSelf() {
    //   let href = this.partners[this.$i18n.locale].filter((item) => item.tag === `ceo_city_guides_${this.theme}`)[0].url;
    //   window.open(href, "_self");
    // },
    pushRouteToRussia() {
      let href = this.partners[this.$i18n.locale].filter((item) => item.tag === 'srpr')[0].url
      window.open(href, "_self");
    },
  }
};
