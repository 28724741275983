<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section-first-page-hero">
      <slider></slider>
      <div class="new-features">
        <div
          class="container m-container-only-s"
          v-html="$t('pages.main_header')"
        ></div>
      </div>
    </section>
    <section class="section section-first-page-tiles">
      <div class="container m-container-only-s">
        <div class="tiles">
          <!-- <div
            class="tile two-cols-left tile-seats bottom"
            :class="{
              'disabled-tile': (isBox && (!isUpgrade || !isService)) || !isBox
            }"
          @click.prevent="$router.push({ name: 'UpgradeSeat' })"
          > -->
          <div class="tile two-cols-left tile-seats bottom disabled-tile">
            <div class="tile-container">
              <!-- <span class="new" v-if="isBox">{{ $t("pages.badge_new") }}</span>
              <span class="soon" v-else>{{
                $t("pages.badge_soon_isPublic")
              }}</span> -->
              <br />
              <p v-html="$t('pages.main_upgrade')"></p>
              <!-- <router-link
                v-if="(isBox && isUpgrade && isService) || !isBox"
                :to="{ name: 'UpgradeSeat' }"
                class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              > -->
              <!-- <span v-else class="disabled-btn">{{ -->
              <span class="disabled-btn">{{
                $t("pages.main_button_disabled")
              }}</span>
            </div>
          </div>

          <a class="tile tile-magazine top" href="/journal/index">
            <div class="tile-container">
              <!-- <span class="new">{{ $t("pages.badge_new") }}</span> -->
              <br />
              <p v-html="$t('pages.main_journal')"></p>
              <a href="/journal/index" class="btn"
                ><span>{{ $t("pages.main_button") }}</span></a
              >
            </div>
          </a>

          <div
            class="tile full-page tile-around bottom"
            @click.prevent="pushRouteToRussia()"
          >
            <div class="tile-container">
              <!-- <span class="new">НОВЫЙ ПРОЕКТ</span><br /> -->
              <p>С&nbsp;«Россией» по&nbsp;России</p>
              <span class="small">
                <!-- <template v-for="(rubric, index) in rubrics">
                  <i
                    @click.stop="pushRoute('/set/partners' + rubric.link)"
                    :key="index"
                    >{{ rubric.title }}</i
                  ><template v-if="rubrics[index + 1]"> • </template>
                </template> -->

                и&nbsp;другие города и&nbsp;регионы</span
              >
              <!-- <a v-if="rubrics.length !== 0" href="" class="btn" -->
              <a href="" class="btn"><span>Подробнее</span></a>
              <!-- <span v-else class="disabled-btn">{{
                $t("pages.main_button_disabled")
              }}</span> -->
            </div>
          </div>
          <div
            class="tile tile-about top"
            @click.prevent="$router.push({ name: 'Airline' })"
          >
            <div class="tile-container">
              <p v-html="$t('pages.main_info')"></p>
              <router-link :to="{ name: 'Airline' }" class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
            </div>
          </div>

          <div
            class="tile two-cols-right tile-entertaiments top"
            @click.prevent="$router.push({ name: 'Main' })"
          >
            <div class="tile-container">
              <p v-html="$t('pages.main_ife')"></p>
              <router-link :to="{ name: 'Main' }" class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section
      v-if="isBox"
      class="section section-branding"
      @click.prevent="pushRouteBlank('/set/partners/yamaguchi/')"
      style="cursor: pointer"
    >
      <div class="branding-container-for-img">
        <picture>
          <source
            srcset="@/assets/img/img-branding-s-new.jpg"
            media="(max-width: 767px)"
          />
          <img src="@/assets/img/img-branding-new.jpg" alt="" />
        </picture>
      </div>
    </section> -->
    <section class="section section-first-page-tiles">
      <div class="container m-container-only-s">
        <div class="tiles">
          <div
            class="tile two-cols-left tile-team bottom "
            :class="{
              'disabled-tile':
                (isBox && !flight.crewEmployers) ||
                (flight.crewEmployers && !flight.crewEmployers.length)
            }"
            @click.prevent="
              isBox
                ? !flight.crewEmployers ||
                  (flight.crewEmployers && !flight.crewEmployers.length)
                  ? false
                  : $router.push({ name: 'Crew' })
                : $router.push({ name: 'CrewPublic' })
            "
          >
            <div class="tile-container">
              <!-- <span class="new" v-if="isBox">{{ $t("pages.badge_new") }}</span> -->
              <span class="soon" v-if="!isBox">{{
                $t("pages.badge_soon_isPublic")
              }}</span>
              <br />
              <p v-html="$t('pages.main_crew')"></p>
              <router-link
                v-if="
                  (isBox &&
                    flight.crewEmployers &&
                    flight.crewEmployers.length) ||
                    !isBox
                "
                :to="{ name: 'Crew' }"
                class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >

              <span v-else class="disabled-btn">{{
                $t("pages.main_button_disabled")
              }}</span>
            </div>
          </div>

          <div
            class="tile tile-press bottom"
            @click.prevent="$router.push({ name: 'Press' })"
          >
            <div class="tile-container">
              <!-- <span class="new">{{ $t("pages.badge_new") }}</span              > -->
              <br />
              <p v-html="$t('pages.main_press')"></p>
              <router-link :to="{ name: 'Press' }" class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
            </div>
          </div>

          <div
            class="tile tile-shop middle"
            :class="{
              'disabled-tile': !isBox
            }"
            @click.prevent="$router.push({ name: 'Shop' })"
          >
            <div class="tile-container">
              <!-- <span class="new" v-if="isBox">{{ $t("pages.badge_soon") }}</span>
              <span class="soon" v-else>{{
                $t("pages.badge_soon_isPublic")
              }}</span
              ><br /> -->
              <p v-html="$t('pages.main_shop')"></p>
              <router-link
                v-if="isBox && isShopOn"
                :to="{ name: 'Shop' }"
                class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
              <span v-else class="disabled-btn">
                <!-- <span class="disabled-btn"> -->
                {{ $t("pages.main_button_disabled") }}
              </span>
            </div>
          </div>

          <div
            :class="{
              'disabled-tile': !isService && isBox
            }"
            class="tile tile-stuard middle"
            @click.prevent="$router.push({ name: 'Services' })"
          >
            <div class="tile-container">
              <!-- <span class="new" v-if="isBox">{{ $t("pages.badge_new") }}</span> -->
              <span class="soon" v-if="!isBox">{{
                $t("pages.badge_soon_isPublic")
              }}</span
              ><br />
              <p v-html="$t('pages.main_board')"></p>
              <router-link
                v-if="(isService && isBox) || !isBox"
                :to="{ name: 'Services' }"
                class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
              <span v-else class="disabled-btn">
                {{ $t("pages.main_button_disabled") }}
              </span>
            </div>
          </div>

          <div
            :class="{
              'disabled-tile': !isFoodMenu && isBox
            }"
            class="tile two-cols-right tile-menu bottom"
            @click.prevent="$router.push({ name: 'Menu' })"
          >
            <div class="tile-container">
              <!-- <span class="new" v-if="isBox">{{ $t("pages.badge_new") }}</span> -->
              <span class="soon" v-if="!isBox">{{
                $t("pages.badge_soon_isPublic")
              }}</span>
              <br />
              <p v-html="$t('pages.main_menu')"></p>
              <router-link
                v-if="(isFoodMenu && isBox) || !isBox"
                :to="{ name: 'Menu' }"
                class="btn"
                ><span>{{ $t("pages.main_button") }}</span></router-link
              >
              <span v-else class="disabled-btn">
                {{ $t("pages.main_button_disabled") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import cover from "@/mixins/cover";
import partners from "@/mixins/partners";

import isFlight from "@/mixins/isFlight";
import Slider from "@/components/Slider";
export default {
  name: "IndexMain",
  components: { Slider },
  title() {
    return this.$t("pages.main");
  },
  mixins: [cover, isFlight, partners],
  data: () => {
    return {
      video: null,
      rubrics: [],
      mainRubric: ""
    };
  },
  computed: {
    ...mapState("home", {
      indexData: state => state.index,
      indexLoading: state => state.indexLoading
    }),
    ...mapState("flight", {
      flight: state => state.flight
    }),
    ...mapState("settings", {
      enabledDrm: state => state.enabledDrm
    }),
    ...mapState({
      locale: state => state.locale
    }),
    ...mapState("section", {
      indexSectionData: state => state.index,
      indexSectionLoading: state => state.indexLoading
    }),
    backgroundHeaderImage() {
      return {
        backgroundImage: `url(${require("@/assets/" +
          this.headerItems[this.step].image)})`
      };
    }
  },
  watch: {
    indexLoading: {
      handler() {
        if (this.indexLoading === "empty") {
          this.actionIndex();
        }
      },
      immediate: true
    }
  },
  //   next(vm => {
  //     vm.getNewJournal();
  //   });
  // },beforeRouteEnter(to, from, next) {

  methods: {
    ...mapActions("home", {
      actionIndex: "index"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    }),
    // pushRoute(href) {
    //   if (this.rubrics.length) {
    //     window.location.href = href;
    //   }
    // },
    // async getNewJournal() {
    //   try {
    //     const { data } = await this.axios.get(
    //       "/set/partners/srpr/rubrics.json"
    //     );
    //     this.rubrics = data.rubrics;
    //     let main = data.rubrics[0].link.split("/");
    //     main.splice(main.length - 1, 1);
    //     main.push("all");

    //     this.mainRubric = main.join("/");
    //   } catch (e) {
    //     console.log(e);
    //     this.rubrics = [];
    //   }
    // },
    pushRouteBlank(href) {
      window.open(href, "_blank");
    }
  }
};
</script>

<style scoped></style>
